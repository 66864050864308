import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_1/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      leftColumn: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-1-1"}}) {
        body
      }
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
